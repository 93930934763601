import webApi from "@/services/api"

export class CustomerDetailService {
    carDetail(data) {
        return webApi.post('car-detail', data)
    }
    getPriceRange(params) {
        return webApi.get('car-detail/price-range-process/car-info-redbook-october/autoflip', { params })
    }
    getListLocations() {
        return webApi.get('location-service')
    }
    getUXJourneyConfig(params) {
        return webApi.get('ux-journey-config/get-config', { params })
    }
    resolveDomain(domain) {
        return webApi.get('dns-validation-service/resolve', { params: { domain } })
    }
    initCarDetails(data) {
        return webApi.post('car-detail/init-car-details', data)
    }
}

const customerDetailService = new CustomerDetailService()

export default customerDetailService
